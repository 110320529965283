import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly path = environment.midUrl;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly _ngZone: NgZone,
    private readonly router: Router
  ) {}

  public signOutExternal = () => {
    sessionStorage.clear();
    localStorage.clear();
    this._ngZone.run(() => {
      this.router.navigate(['/']).then(() => window.location.reload());
    });
  };

  LoginWithGoogle(credentials: string): Observable<any> {
    const header = new HttpHeaders().set('Content-type', 'application/json');
    return this.httpClient.post(
      this.path.loginWithGoogle,
      JSON.stringify({ credential: credentials }),
      { headers: header }
    );
  }
}
