import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          this.logoutMessage();
          setTimeout(() => {
            this.authService.signOutExternal();
          }, 1000);
        }
        return throwError(() => new Error(error.message));
      })
    );
  }

  private logoutMessage() {
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: 'Cerrando sesión',
      text: 'Por motivos de seguridad la sesión se cerrará en unos segundos...',
      showConfirmButton: false,
      timer: 4000,
    });
  }
}
